import { Label } from '../Label/Label';
import './AttendanceLegend.css';

/**
 * Shows a legend of attendance letters to its categories.
 *
 * @constructor
 */
export const AttendanceLegend = () => {
    const attendanceKeys = [
        { key: 'A', value: 'Anwesend' },
        { key: 'A+', value: 'Anwesend mit Verstpätung' },
        { key: 'UE', value: 'Fehltag ohne wichtigen Grund (unentschuldigt)' },
        { key: 'E', value: 'Fehltag mit wichtigem Grund (entschuldigt)' },
        { key: 'U', value: 'Unterweisungsfreie Zeit (inkl. Urlaub, Feiertage)' },
        { key: 'K', value: 'Krank (ärztlich bescheinigte Krankheitszeiten)' },
        { key: 'S', value: 'Sonstiger Fehltag (nicht E, UE oder K)' },
        { key: 'VG', value: 'Vorstellungsgespräch' },
        { key: 'P', value: 'Praktikum' },
        { key: 'M', value: 'Maßnahmenende' },
        { key: 'T', value: 'Telefon' },
        { key: 'V', value: 'Videotelefonie' }
    ];

    return (
        <div className="attendance-legend">
            {attendanceKeys.map((attendanceKey, index) => {
                return (
                    <Label key={index} size={1}>
                        <div>
                            {attendanceKey.key}: {attendanceKey.value}
                        </div>
                    </Label>
                );
            })}
        </div>
    );
};
